var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-package-coupon",
      "size": "md",
      "centered": "",
      "header-bg-variant": "light-info",
      "title": _vm.$t('packageConfig.coupon.createCoupon'),
      "ok-title": _vm.$t('packageConfig.coupon.create'),
      "cancel-title": _vm.$t('close'),
      "cancel-variant": "flat-dark",
      "ok-disabled": _vm.okDisabled,
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    on: {
      "show": _vm.showHandle,
      "ok": _vm.okHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('BForm', [_c('div', {
    staticClass: "d-flex flex-column align-items-start justify-content-start",
    staticStyle: {
      "min-height": "200px"
    }
  }, [_c('BCol', {
    staticClass: "d-flex-center justify-content-end px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('BFormCheckbox', {
    staticClass: "mb-50",
    model: {
      value: _vm.isCheckPercentage,
      callback: function callback($$v) {
        _vm.isCheckPercentage = $$v;
      },
      expression: "isCheckPercentage"
    }
  }, [_vm._v(" Giảm giá theo % ")])], 1), _vm.isCheckPercentage ? _c('BCol', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "".concat(_vm.isCheckPercentage ? 'required|max_value:100|min_value:0' : ''),
      "name": _vm.$t('packageConfig.columns.percentage')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-class": "h6",
            "label-for": "percentage"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.columns.percentage')) + " "), _vm.isCheckPercentage ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "value": _vm.percentage,
            "name": _vm.$t('packageConfig.columns.percentage')
          }
        }), _c('b-input-group', {
          staticClass: "input-group-merge"
        }, [_c('IAmInputMoney', {
          staticClass: "flex-grow-1 unset-border-right",
          staticStyle: {
            "min-width": "150px"
          },
          attrs: {
            "id": "percentage",
            "value-money": _vm.percentage,
            "state": errors[0] ? false : null,
            "name": _vm.$t('packageConfig.columns.percentage'),
            "placeholder": _vm.$t('packageConfig.columns.percentage')
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              _vm.percentage = $event;
            },
            "update:value-money": function updateValueMoney($event) {
              _vm.percentage = $event;
            }
          }
        }), _c('b-input-group-append', {
          class: {
            'border-state': errors[0]
          },
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PercentIcon"
          }
        })], 1)], 1)], 1)];
      }
    }], null, false, 3217606225)
  })], 1) : _vm._e(), _c('BCol', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "".concat(_vm.isCheckPercentage ? '' : 'required|min_value:0'),
      "name": _vm.$t('packageConfig.columns.amountMax')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-class": "h6",
            "label-for": "amountMax"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.isCheckPercentage ? 'Giá trị tối đa' : _vm.$t('packageConfig.columns.amountMax')) + " "), !_vm.isCheckPercentage ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "value": _vm.amountMax,
            "name": _vm.$t('packageConfig.columns.amountMax')
          }
        }), _c('b-input-group', {
          staticClass: "input-group-merge"
        }, [_c('IAmInputMoney', {
          staticClass: "flex-grow-1 unset-border-right",
          staticStyle: {
            "min-width": "150px"
          },
          attrs: {
            "id": "amountMax",
            "value-money": _vm.amountMax,
            "state": errors[0] ? false : null,
            "name": _vm.$t('packageConfig.columns.amountMax'),
            "placeholder": _vm.isCheckPercentage ? 'Giá trị tối đa' : _vm.$t('packageConfig.columns.amountMax')
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              _vm.amountMax = $event;
            },
            "update:value-money": function updateValueMoney($event) {
              _vm.amountMax = $event;
            }
          }
        }), _c('b-input-group-append', {
          class: {
            'border-state': errors[0]
          },
          attrs: {
            "is-text": ""
          }
        }, [_vm._v(" VND ")])], 1)], 1)];
      }
    }])
  })], 1)], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }